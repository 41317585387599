import * as AuthenticationActions from './authentication.actions';
import * as AuthenticationApiActions from './authentication-api.actions';
import * as UserPasswordActions from './user-password.actions';
import * as UserPasswordApiActions from './user-password-api.actions';
import * as ApplicationActions from './application.actions';
import * as MentorPersonaActions from './mentor-persona.actions';

export {
  AuthenticationActions,
  AuthenticationApiActions,
  UserPasswordActions,
  UserPasswordApiActions,
  ApplicationActions,
  MentorPersonaActions,
};

/**
 * Union of authentication actions types
 */
export type AuthenticationActionsTypes =
  AuthenticationActions.AuthenticationActionsUnion |
  AuthenticationApiActions.AuthenticationApiActionsUnion |
  UserPasswordActions.UserPasswordActionsUnion |
  UserPasswordApiActions.UserPasswordApiActionsUnion |
  MentorPersonaActions.MentorPersonaActionsUnion |
  ApplicationActions.ApplicationActionsUnion;
