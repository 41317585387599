import * as HeaderTitleActions from './header-title.actions';
import * as SideNavActions from './side-nav.actions';
import * as LoadingSpinnerActions from './loading-spinner.actions';

export {
  HeaderTitleActions,
  SideNavActions,
  LoadingSpinnerActions,
};

/**
 * Union of generic actions types
 */
export type GenericActionsTypesUnion =
  HeaderTitleActions.HeaderTitleActionsUnion |
  SideNavActions.SideNavActionsUnion |
  LoadingSpinnerActions.LoadingSpinnerActionsUnion;
